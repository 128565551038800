import React from "react"
import HeroCarousel from "../components/HeroCarousel"
import NewsletterForm from "../components/NewsletterForm"
import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <HeroCarousel />
  </>
)

export default IndexPage
