import React, { useEffect, useReducer } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { config, useTransition } from "react-spring"
import { Slide, HeroImage } from "./styles"

const HeroCarousel = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "BRAIDS_WebWireframe-07.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroImages: allPrismicBackgroundImage {
        edges {
          node {
            data {
              description
              order
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const allHeroImages = data.heroImages.edges
    .sort((a, b) => a.node.data.order - b.node.data.order)
    .map((item, index) => {
      return {
        image: item.node.data.image.fluid,
        key: index,
        description: item.node.data.description,
      }
    })

  const initialState = {
    count: 0,
    items: allHeroImages,
    currentItem: allHeroImages[0],
  }

  function reducer(state, action) {
    switch (action.type) {
      case "increment":
        return {
          count: state.count < allHeroImages.length - 1 ? state.count + 1 : 0,
          items: state.items,
          currentItem: allHeroImages[state.count],
        }
      default:
        console.log("error")
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: "increment" })
    }, 20000)

    return () => clearInterval(interval)
  }, [])

  const transitions = useTransition(state.currentItem, item => item.key, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  })

  return (
    <div>
      {transitions.map(({ item, props, key }) => {
        return (
          <Slide style={props} key={key}>
            <HeroImage fluid={item.image} alt={item.description} />
          </Slide>
        )
      })}
    </div>
  )
}

export default HeroCarousel
