import styled from "styled-components"
import { animated } from "react-spring"
import Img from "gatsby-image/withIEPolyfill"

export const HeroImage = styled(Img)`
  object-fit: cover;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 0;

  & img {
    margin: 0;
  }
`

export const Slide = styled(animated.div)`
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 0;
`
